import Headline from "components/general/headline"
import Layout from "components/layout"
import PrivacyPolicy from "components/privacy-policy"
import * as React from "react"

const PrivacyPage = () => {
  const seo = {
    metaTitle: "Datenschutz",
    metaDescription: "Datenschutz",
  }

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline headline="Datenschutz" subHeadline={undefined} />

        <PrivacyPolicy />
      </div>
    </Layout>
  )
}

export default PrivacyPage
