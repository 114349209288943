import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

const query = graphql`
  {
    strapiPrivacyPolicy {
      textblock {
        headline
        content
      }
    }
  }
`

const PrivacyPolicy = () => (
  <StaticQuery
    query={query}
    render={data => {
      return (
        <div>
          {data.strapiPrivacyPolicy.textblock.map(c => (
            <>
              <h2 className="mt-8 text-3xl">{c.headline}</h2>
              <div className="strapi-content">
                <Markdown
                  children={c.content}
                  remarkPlugins={[remarkGfm]}
                  className="article mt-2 leading-6"
                />
              </div>
            </>
          ))}
        </div>
      )
    }}
  />
)

export default PrivacyPolicy
